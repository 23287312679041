* {
  font-family: 'Roboto', sans-serif !important;
}

.container-navbar2-loftyapps {
  min-height: 100px;
}

.navbar2-loftyapps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.logo2-loftyapps {
  width: 265px;
}

.item-navbar2-loftyapps {
  display: flex;
  width: 62%;
  justify-content: space-around;
}

.link-navbar2-loftyapps {
  color: #201549 !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  text-decoration: none;
}

.link-navbar2-loftyapps:hover {
  color: #29b2ef !important;
}

.text-navbar2-loftyapps {
  background-color: #201549;
  color: white;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.58px;
  text-align: center;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link2-navbar2-loftyapps {
  color: #29b2ef !important;
  text-decoration: none;
}

.imgcircule-loftyapps {
  position: relative !important;
  width: 574px;
  height: 574px;
}

.section-loftyapps {
  padding-top: 50px;
  padding-bottom: 50px;
}

.imgcircule-loftyapps {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgpeaple-loftyapps {
  width: 446px;
  z-index: 3;
}

.title1-loftyapps {
  font-size: 47px;
  font-weight: 700;
  line-height: 55.08px;
  color: #201549 !important;
}

.title2-loftyapps {
  font-size: 47px;
  font-weight: 700;
  line-height: 55.08px;
  color: #29b2ef !important;
}

.title3-loftyapps {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
}

.text-loftyapps {
  font-size: 17px;
  font-weight: 300;
  line-height: 16.41px;
  color: #201549;
}

/* Animaciones de imagenes */

.img-web-animation-loftyapp {
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  position: absolute;
  animation: circularTranslate 10s forwards infinite;
  animation-timing-function: linear;
}

.img-location-animation-loftyapp {
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  position: absolute;
  animation: circularTranslate 10s forwards infinite;
  animation-timing-function: linear;
  animation-delay: 2.5s;
}

.img-cloud-animation-loftyapp {
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  position: absolute;
  animation: circularTranslate 10s forwards infinite;
  animation-timing-function: linear;
  animation-delay: 5s;
}

.img-backup-animation-loftyapp {
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  position: absolute;
  animation: circularTranslate 10s forwards infinite;
  animation-timing-function: linear;
  animation-delay: 7.5s;
}

@keyframes circularTranslate {
  from {
    -webkit-transform: rotate(0deg) translateX(287px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translateX(287px) rotate(-360deg);
  }
}

/* Animacion del texto */

.content-list-animation-loftyapps {
  height: 50px;
  overflow: hidden;
}

.container-list-text-animation-loftyapps {
  margin-top: 0;
  padding-left: 110px;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  overflow: hidden;
}

.item-text-animation-loftyapps {
  line-height: 52px;
  margin: 0;
}

@-webkit-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

/* Secciones */

.padding-container-loftyapps,
.padding-container-loftyapps-section-consult {
  padding-left: 3%;
  padding-right: 3%;
}

.section-gray-loftyapps {
  background-color: #e9edf4;
  min-height: 393px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.section-white-loftyapps {
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.card-loftyapps {
  border-radius: 5px !important;
  border: 0.5px solid #c4c4c4;
  box-shadow: 0 4px 40px 0px #eae5e5 !important;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 85%;
  margin: 0 auto;
}

.card-blue-loftyapps {
  padding: 5%;
  border-radius: 10px;
  background-color: #14569f !important;
  box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  width: 85%;
}

.card-blue-outline-loftyapps {
  border-radius: 10px;
  border: 1px solid #29b2ef;
  background: #fff;
  min-height: 263px;
}

.text-white {
  color: white !important;
}

.container-video-loftyapps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.border-botton-client-loftyapps {
  border-bottom: 1px solid #212529;
  min-height: 380px;
}

.card-client-loftyapps {
  border-radius: 10px !important;
  background: #fff !important;
  width: 472px;
  box-shadow: 15px 10px 50px 0px rgba(0, 0, 0, 0.1) !important;
}

.container-cards-client-loftyapps {
  margin-bottom: -700px;
}

.footer-loftyapps {
  background: #201549;
  padding-top: 50px;
  padding-bottom: 50px;
}

.icon-redsocial-loftyapps {
  margin: 1rem;
}

.card-enterprice-loftyapps {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 535px;
  height: 250px;
}

.info-client-card {
  display: flex;
  justify-content: space-between;
}

.section-client-loftyapps {
  height: 400px;
}

.visual-future-loftyapps {
  width: 481px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.footer-text-loftyapps {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.button-menu-loftyapps {
  display: none;
}

.container-button-loftyapps {
  display: flex;
  justify-content: end;

}

/* Tableta */
@media only screen and (max-width: 1025px) {
  .padding-container-loftyapps-section-consult {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .imgcircule-loftyapps {
    width: 300px;
    height: 300px;
    background-size: contain;
  }

  .imgpeaple-loftyapps {
    width: 233px;
  }

  .img-web-animation-loftyapp {
    width: 52px;
  }

  .img-location-animation-loftyapp {
    width: 52px;
  }
  .img-cloud-animation-loftyapp {
    width: 52px;
  }
  .img-backup-animation-loftyapp {
    width: 52px;
  }

  @keyframes circularTranslate {
    from {
      -webkit-transform: rotate(0deg) translateX(157px) rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg) translateX(157px) rotate(-360deg);
    }
  }

  .title1-loftyapps {
    font-size: 25px !important;
  }

  .title2-loftyapps {
    font-size: 25px;
  }

  .title3-loftyapps {
    font-size: 12px;
  }

  .card-enterprice-loftyapps {
    min-height: auto;
  }

  .container-video-loftyapps {
    flex-direction: column-reverse;
  }

  .item-navbar2-loftyapps {
    font-size: 12px;
  }

  .logo2-loftyapps {
    width: 199px;
  }

  .text-navbar2-loftyapps {
    font-size: 12px;
  }

  .link-navbar2-loftyapps {
    font-size: 12px;
  }

  .info-client-card {
    flex-direction: column-reverse;
  }

  .section-client-loftyapps {
    height: 650px;
  }
}

@media only screen and (max-width: 575px) {
  .imgcircule-loftyapps {
    width: 230px;
    height: 230px;
    background-size: contain;
  }

  .imgpeaple-loftyapps {
    width: 179px;
  }

  .img-web-animation-loftyapp {
    width: 40px;
    margin-top: -10px;
    margin-left: -10px;
  }

  .img-location-animation-loftyapp {
    width: 40px;
    margin-top: -10px;
    margin-left: -10px;
  }
  .img-cloud-animation-loftyapp {
    width: 40px;
    margin-top: -10px;
    margin-left: -10px;
  }
  .img-backup-animation-loftyapp {
    width: 40px;
    margin-top: -10px;
    margin-left: -10px;
  }

  @keyframes circularTranslate {
    from {
      -webkit-transform: rotate(0deg) translateX(135px) rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg) translateX(135px) rotate(-360deg);
    }
  }

  .title-landing-loftyapps {
    text-align: center !important;
    margin-top: 50px !important;
  }

  .subtitle-landing-loftyapps {
    text-align: center !important;
  }

  .title1-loftyapps {
    font-size: 20px !important;
  }

  .title2-loftyapps {
    font-size: 20px;
  }

  .title3-loftyapps {
    font-size: 15px;
  }

  .text-landing-loftyapps {
    text-align: center !important;
  }

  .logo2-loftyapps {
    width: 150px;
  }

  .item-navbar2-loftyapps {
    display: none;
  }

  .text-navbar2-loftyapps {
    font-size: 11px;
    min-height: 68px;
  }

  .container-advantage-loftyapps {
    display: flex;
    justify-content: center;
  }

  .youtube-loftyapps {
    width: 300px;
    height: 168px;
  }

  .card-enterprice-loftyapps {
    min-height: auto;
  }

  .section-client-loftyapps {
    height: 1100px;
  }

  .container-video-loftyapps {
    flex-direction: column-reverse;
  }

  .visual-future-loftyapps {
    width: 100%;
  }

  .footer-text-loftyapps {
    flex-direction: column;
    align-items: center;
    min-height: 200px;
  }

  .button-menu-loftyapps {
    display: block;
  }

  .container-button-loftyapps {
    display: flex;
    justify-content: center;
  }
}

.faq-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #14569e;
  font-size: 2.9375rem;
  width: 100%;
}

.questions-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 4rem;
  background: #f3f5f6;
}

.questions-container {
  background-color: white;
  width: 88%;
}

.question {
  display: flex;
  flex-wrap: wrap;
  padding: 3.25rem 3.75rem;
  border-bottom: 2px solid #f3f5f6;
}


.question-number {
  margin-right: 3.25rem;
  color: #29b2ef;
  /* Large title/3 strong */
  font-family: Inter;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 3.6rem */
  letter-spacing: 0.01563rem;
}

.number-hidden {
  color: rgba(41, 178, 239, 0.3) !important;
}

.question-text {
  width: 80%;
}

.plus-column {
  width: 10%;
}

.question-title {
  color: #170f49;
  /* Title/1 strong */
  font-family: Inter;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 2.4rem */
}

.question-answer {
  color: rgba(60, 60, 67, 0.85);
  /* Text/1 regular */
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.75rem */
  margin-top: 1.5rem;
  height: fit-content;
  overflow: hidden;
  transition: 0.8s ease-in-out !important;
}

.answer-hidden {
  height: 0px;
}

.plus-container {
  width: 3rem;
  height: 3rem;
  margin: auto;
  cursor: pointer;
  background-color: #f3f5f6;
  border-radius: 100px 100px 100px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out !important;
}

.rotated {
  transform: rotate(45deg);
  background: #6e798c;
}

.plus {
  width: 0.7rem;
  height: 0.7rem;
  margin: 1rem;
  fill: #170f49;
}

.cross {
  fill: white;
}

@media only screen and (max-width: 575px) {
  .question-number{
    min-width: 100%;
    font-size: 2rem;
  }

  .question-title {
    font-size: 1rem;
  }

  .question-answer {
    font-size: 1rem;
  }

  .question {
    padding: 1rem;
    padding: 1.5rem;
  }

  .questions-section {
    padding: 2rem 1rem;
  }
}
