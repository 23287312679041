.main-cs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 110vh;
  width: 100%;
}

.cs-lofty-logo {
  width: 10rem;
}

.main-cs-title {
  color: #14569e;
  text-align: center;
  font-family: Inter;
  font-size: 4.0625rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3.9375rem; /* 96.923% */
  letter-spacing: -0.08125rem;
  margin-bottom: 4rem;
}

.main-cs-subtitle {
  color: #292151;
  text-align: center;
  font-family: Inter;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.02625rem;
}

@media only screen and (max-width: 1024px) {
  .main-cs-subtitle {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 2rem;
  }
}

@media only screen and (max-width: 787px) {
  .main-cs-title {
    font-size: 2.5rem;
  }
  .main-cs-subtitle {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.4rem;
  }
}