.block-title-area {
  display: flex;
  width: 600px;
  padding: 40px;
  margin-left: 30px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid rgba(232, 232, 234, 1);
  box-shadow: 0px 12px 24px -6px rgba(24, 26, 42, 0.12);
  background-color: #fff;
  margin-bottom: -5px;
}

.picture-style-background {
  height: 600px;
}

.title-block-cover-style {
  color: rgba(24, 26, 42, 1);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 116.667% */
}

.title-blog-info-main {
  color: var(--neutral-800, #170F49);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  
  /* Headings/Typography Size 1 */
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px; /* 124% */
}

.subtitle-blog-info-main {
  color: var(--neutral-600, #6F6C90);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  
  /* Paragraph/Default */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  padding: 1rem;
}

.blog-info-cover-style-background {
  width: 75%;
  height: 600px;
  object-fit: cover;
  border-radius: 10px;
}

.container-info-blog {
  width: 55%;
}

.paragraph-info-blog {
  color: #201549;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 20px;
}

.image-info-blog {
  width: 100%;
  height: 490px;
  object-fit: cover;
  border-radius: 10px;
}

.divisor-info-blog{
  width: 100%; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 75px;
  margin-bottom: 50px;
}

.bar-divisor-info-blog {
  background: #958ACC;
  width: 8px;
  height: auto;
}

.text-divisor-info-blog{
  color: var(--gray-800, #1D2939);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 44.8px */
  margin-left: 1rem;
}

.grid-articles-style {
  width: 75% !important;
}

/* MEDIA QUERIES */

@media only screen and (max-width:1024px) {
  .block-title-area {
    width: 300px;
    margin-bottom: -55px;
  }
  .picture-style-background {
    height: 400px;
  }
  .blog-info-cover-style-background {
    width: 90%;
    height: 450px;
  }
  .container-info-blog {
    width: 95%;
  }
  .divisor-info-blog {
    margin-top: 50px;
    margin-bottom: 75px;
  }
  .paragraph-info-blog {
    font-size: 15px;
  }
  .text-divisor-info-blog {
    font-size: 29px;
  }
  .grid-articles-style {
    width: 90% !important;
  }
  .title-blog-info-main {
    font-size: 36px;
    line-height: 41px;
  }
  .subtitle-blog-info-main {
    font-size: 15px;
    line-height: 23px;
  }
}

@media only screen and (max-width: 500px) {
  .block-title-area {
    width: 200px;
    margin-left: 1rem;
    padding: 17px 18px;
  }
  .picture-style-background {
    height: 220px;
  }
  .title-block-cover-style {
    font-size: 12px;
    line-height: 15px;
  }
  .blog-info-cover-style-background {
    height: 350px;
  }
  .paragraph-info-blog {
    font-size: 12px;
    line-height: 21px;
  }
  .image-info-blog {
    height: 280px;
  }
  .divisor-info-blog {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .text-divisor-info-blog {
    font-size: 19px;
  }
  .grid-articles-style {
    width: 95% !important;
  }
  .title-blog-info-main {
    font-size: 25px;
    line-height: 31px;
  }
  .subtitle-blog-info-main {
    font-size: 16px;
  }
}