
/** -- PRICE PLAN AREA --**/
.header-price-plan-landing-style {
  margin-top: 100px;
  margin-bottom: 50px;
}
.title-price-plan-landing {
  color: var(--neutral-800, #170F49);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Headings/Typography Size 1 */
  font-family: Inter;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  /* 124% */
}

.subtitle-price-plan-landing {
  color: var(--neutral-600, #6F6C90);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  width: 40%;
  /* Paragraph/Default */
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

.card-blue-plan-subscription {
  border-radius: 20px;
  border: 1px solid var(--neutral-300, #EFF0F6);
  background: #14569E;
  padding: 50px 30px;
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
}

.middle-section-card-blue-plan-subscription-info-area {
  width: 60%;
}

.middle-section-card-blue-plan-subscription-divisor {
  background: #DF9405;
  height: 1px;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.middle-section-card-blue-plan-subscription-title {
  color: #FFF;
  font-family: Inter;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  /* 102.857% */
}

.middle-section-card-blue-plan-subscription-subtitle {
  color: #FFF;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  /* 176.923% */
  letter-spacing: -0.26px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 75%;
}

.middle-section-card-blue-plan-subscription-button-container {
  width: 20%;
  align-self: center;
}

.middle-section-card-blue-plan-subscription-button {
  display: flex;
  width: auto;
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
  border-radius: 8px;
  background: #4F9CF9;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.pricing-card-price-plan-subscription {
  width: 50%;
  display: flex;
  filter: drop-shadow(-28px 20px 63px #BCBCBC);
  flex-direction: row;
  min-height: 400px;
}

.pricing-card-price-plan-subscription-left-wing {
  width: 60%;
  border-radius: 20px 0px 0px 20px;
  border: 1px solid var(--neutral-300, #EFF0F6);
  background: var(--neutral-100, #FFF);
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.pricing-card-price-plan-subscription-title {
  color: #212529;

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  /* 150% */
}

.pricing-card-price-plan-subscription-content {
  color: #212529;

  /* Paragraph/P2/Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  /* 127.778% */
  letter-spacing: -0.36px;
}

.pricing-card-price-plan-subscription-cost {
  color: #14569E;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.5px;
}

.pricing-card-price-plan-subscription-button {
  display: flex;
  width: 138px;
  height: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #DF9405;
  background: #FFF;
  color: #212529;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
  cursor: pointer;
}

.pricing-card-price-plan-subscription-right-wing {
  border-radius: 0px 20px 20px 0px;
  background: #14569E;
  padding: 50px 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.pricing-card-price-plan-subscription-include-title {
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 111.111% */
}

.pricing-card-price-plan-subscription-include-item-text {
  color: var(--Gray-5, #E0E0E0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}

/* - RESPONSIVE PRICE PLANS - */

@media only screen and (max-width: 1300px) {
  .subtitle-price-plan-landing {
    width: 60%;
  }
  .card-blue-plan-subscription {
    width: 80%;
  }
  .pricing-card-price-plan-subscription {
    width: 60%;
  }
}

@media only screen and (max-width: 1024px) {
  .title-price-plan-landing {
    font-size: 40px;
  }
  .subtitle-price-plan-landing {
    font-size: 16px;
  }
  .pricing-card-price-plan-subscription {
    width: 60%;
  }
  .card-blue-plan-subscription {
    width: 90%;
  }
  .middle-section-card-blue-plan-subscription-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 787px) {
  .pricing-card-price-plan-subscription {
    width: 85%;
  }
  .middle-section-card-blue-plan-subscription-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 540px) {
  .header-price-plan-landing-style {
    margin-top: 50px;
  }
  .title-price-plan-landing {
    font-size: 19px;
    margin-bottom: 75px;
  }
  .subtitle-price-plan-landing {
    display: none;
  }
  .card-blue-plan-subscription {
    width: 80%;
    flex-direction: column;
  }
  .middle-section-card-blue-plan-subscription-info-area {
    width: 100%;
  }
  .middle-section-card-blue-plan-subscription-title {
    font-size: 20px;
  }
  .middle-section-card-blue-plan-subscription-subtitle {
    width: 100%;
  }
  .middle-section-card-blue-plan-subscription-button-container {
    width: 100%;
  }
  .middle-section-card-blue-plan-subscription-button {
    width: 100%;
  }
  .pricing-card-price-plan-subscription {
    flex-direction: column;
  }
  .pricing-card-price-plan-subscription-left-wing {
    width: 85%;
    border-radius: 20px 20px 0px 0px;
    min-height: 250px;
  }
  .pricing-card-price-plan-subscription-button {
    margin-top: 25px;
    width: 100%;
  }
  .pricing-card-price-plan-subscription-right-wing {
    width: 85%;
    border-radius: 0px 0px 20px 20px;
  }
}